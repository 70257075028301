:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: rgb(0, 210, 122);
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    --toastify-color-progress-light: linear-gradient(to right,
            rgb(0, 210, 122),
            #5ac8fa,
            #007aff,
            #34aadc,
            #5856d6,
            #ff2d55);
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
}

.scrollspy {
    list-style: none;
    padding-left: 0
}

.scrollspy li a {
    border-left: 3px solid transparent;
    color: var(--falcon-body-color);
    display: block;
    padding: .9375rem 1.25rem;
    position: relative
}

.scrollspy li a.disabled,
.scrollspy li a:disabled {
    background-color: var(--falcon-list-group-disabled-bg);
    color: var(--falcon-list-group-disabled-color);
    pointer-events: none
}

.scrollspy li.active a,
.scrollspy li:focus a,
.scrollspy li:hover a {
    color: var(--falcon-link-color)
}

.scrollspy li.active a {
    border-color: var(--falcon-link-color)
}

a,
a:hover {
    text-decoration: none
}

.nav-link a{
    color: inherit;
}

.dropdown-toggle::after{
    display: none !important;
}

/*LAYOUT*/